<template>
  <div
    v-if="banner"
    class="mm-warning-message"
  >
    <SvgIcon
      class="mm-warning-message__icon"
      :src="EIconPath.NavigationInfo"
    />
    <span class="mm-warning-message__text">
      {{ banner.message }}
    </span>
    <a
      class="mm-warning-message__link"
      :href="sanitizeUrl(banner.linkUrl)"
    >{{ banner.linkTitle }}</a>
  </div>
</template>

<script lang="ts" setup>
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useAccountBannersStore } from 'store/accountBanners.store';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { EUserType } from 'enums/user.enum';


const props = withDefaults(
  defineProps<{
    variant: EUserType;
  }>(),
  {
    variant: EUserType.Client,
  },
);

const accountBannersStore = useAccountBannersStore();

const banner = computed(() => accountBannersStore.accountBanners[props.variant]);

watch(() => props.variant, accountBannersStore.loadAccountBanner, { immediate: true });
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-warning-message {
  padding: 16px 40px;
  background-color: rgba(241, 53, 53, 0.1);
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: $text-black;

  &__icon {
    margin-right: 12px;
    min-width: 20px;
    min-height: 20px;
  }

  &__text {
    margin-right: 8px;
  }

  &__link {
    color: $link;
    text-decoration: none;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: $text-dark-green;
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-warning-message {
    padding: 16px 32px;
  }
}

@media only screen and (max-width: 1279px) {
  .mm-warning-message {
    padding: 16px 20px;
  }

  :global(.mm-header) {
    margin-top: 14px;
  }
}
</style>
