import { defineStore } from 'pinia';
import { IStrapiAccountBannerResponse } from 'models/strapi/strapiNews.model';
import { NewsApiService } from 'services/api/newsApi.service';
import { EUserType } from 'enums/user.enum';

export const useAccountBannersStore = defineStore('accountBannersStore', () => {
  const accountBanners = reactive<{
    /** Перетяжка для ЛК клиента */
    [EUserType.Client]?: IStrapiAccountBannerResponse;
    /** Перетяжка для ЛК поставщика */
    [EUserType.Supplier]?: IStrapiAccountBannerResponse;
  }>({});

  const loadAccountBanner = async(type: EUserType) => {
    if (process.server || accountBanners[type] !== undefined) {
      return;
    }

    try {
      accountBanners[type] = await NewsApiService.getAccountBanner(type);
    } catch (error) {
      console.error(error);
    }
  };

  return { accountBanners, loadAccountBanner };
});
